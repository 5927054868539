<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-title>View Incident</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-title size="large">View Incident</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-item v-if="loading">
                <ion-label>Loading</ion-label>
                <ion-spinner color="primary"></ion-spinner>
            </ion-item>
            <template v-else>
                <ion-item>
                    <ion-label>Incident ID:</ion-label>
                    {{ incident.identifier }}
                </ion-item>
                <ion-item>
                    <ion-label>Date Occurred:</ion-label>
                    {{ formatDate(incident.occurred_at) }}
                </ion-item>
                <ion-item>
                    <ion-label class="ion-text-wrap">Location Of Incident:
                        {{ incident.location }}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label class="ion-text-wrap">Person(s) Involved / Witnesses:
                        {{ incident.persons }}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label class="ion-text-wrap">Incident Type:
                        {{ incident.is_accident ? 'Accident/Injury/Motor Vehicle/Mobile Equipment' : 'Near Miss' }}</ion-label>
                </ion-item>
                <ion-list>
                    <ion-list-header>
                        <ion-label>Incident Category:</ion-label>
                    </ion-list-header>
                    <ion-item>
                        <ion-label>Mobile Equipment:</ion-label>
                        <ion-icon :icon="incident.entities.includes('equipment') ? checkbox : squareOutline"/>
                    </ion-item>
                    <ion-item>
                        <ion-label>Property Or Non-Mobile Equipment:</ion-label>
                        <ion-icon :icon="incident.entities.includes('property') ? checkbox : squareOutline"/>
                    </ion-item>
                    <ion-item>
                        <ion-label>Vehicle</ion-label>
                        <ion-icon :icon="incident.entities.includes('vehicle') ? checkbox : squareOutline"/>
                    </ion-item>
                    <ion-item v-if="incident.injuries.length">
                        <ion-label class="ion-text-wrap">Injury - Body Part:
                            {{ incident.injuries.map((s) => { return s[0].toUpperCase() + s.substring(1); }).join(', ') }}</ion-label>
                    </ion-item>
                </ion-list>
                <ion-item>
                    <ion-label class="ion-text-wrap">Incident Description:
                        {{ incident.report }}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label class="ion-text-wrap">Injury Description:
                        {{ incident.injury_description }}</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label class="ion-text-wrap">Medical Facility Used:
                        {{ incident.medical_facility }}</ion-label>
                </ion-item>
                <ion-grid>
                    <ion-row>
                        <ion-col size="6" :key="attachment" v-for="attachment in incident.attachments">
                            <ion-img :src="attachment.temporaryUrl"></ion-img>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </template>
            <ion-button @click="$router.push({ name: 'incidents' })" color="dark" expand="block">Go Back</ion-button>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonSpinner, IonLabel, IonIcon,
    IonList, IonListHeader, IonGrid, IonRow, IonCol, IonImg, IonButton } from '@ionic/vue';
import { checkbox, squareOutline } from 'ionicons/icons';
import Api from '@/services/Api';
import moment from 'moment';

export default {
    name: 'ReportIncident',
    components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonSpinner, IonIcon,
        IonLabel, IonList, IonListHeader, IonGrid, IonRow, IonCol, IonImg, IonButton },
    setup(){
        return {
            checkbox,
            squareOutline,
        };
    },
    data(){
        return {
            loading: true,
            error: null,
            incident: {
                /* eslint-disable */
                identifier: null,
                is_accident: false,
                occurred_at: null,
                location: null,
                latitude: null,
                longitude: null,
                report: null,
                persons: null,
                entities: [],
                injuries: [],
                injury_description: null,
                medical_facility: null,
                attachments: [],
                /* eslint-enable */
            },
        };
    },
    async mounted(){
        const response = await Api.incidents.view(this.$route.params.id);
        if(response.success){
            this.incident = response.incident;
        } else {
            this.error = response.error;
        }
        this.loading = false;
    },
    methods: {
        formatDate(date){
            return date ? moment(date).format('MMMM Do YYYY, h:mm:ss a') : '';
        },
    }
};
</script>
